<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.16769 4.14089L2.29956 10.253C1.85262 10.6507 1.85262 11.3493 2.29956 11.7471L9.16769 17.8592C9.49008 18.1461 10.0001 17.9173 10.0001 17.4857V14C17.6564 13.5496 20.1202 16.7016 21.63 19.8896C21.726 20.0924 22.0294 20.0136 22.0064 19.7905C20.929 9.34281 14.3456 7.99999 10.0001 7.99998V4.51441C10.0001 4.08285 9.49008 3.85399 9.16769 4.14089Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
