<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4V19.2264L7.14473 18.3871C7.48774 18.1356 7.90199 18 8.32732 18H18V7.21875V4H6ZM4 4C4 2.89543 4.89543 2 6 2H18C19.1046 2 20 2.89543 20 4V7.21875V18C20 19.1046 19.1046 20 18 20H8.32732L5.90955 21.7727C5.11691 22.3539 4 21.7878 4 20.805V4ZM11 7C11 6.44772 11.4477 6 12 6H15C15.5523 6 16 6.44772 16 7C16 7.55228 15.5523 8 15 8H12C11.4477 8 11 7.55228 11 7ZM12 10C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12H15C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10H12ZM11 15C11 14.4477 11.4477 14 12 14H15C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16H12C11.4477 16 11 15.5523 11 15ZM9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8C9.55228 8 10 7.55228 10 7C10 6.44772 9.55228 6 9 6ZM8 11C8 10.4477 8.44772 10 9 10C9.55228 10 10 10.4477 10 11C10 11.5523 9.55228 12 9 12C8.44772 12 8 11.5523 8 11ZM9 14C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16C9.55228 16 10 15.5523 10 15C10 14.4477 9.55228 14 9 14Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
