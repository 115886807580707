<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 9V2H5V9C5 9.74028 5.4022 10.3866 6 10.7324L6 2H8L8 10.7324C8.5978 10.3866 9 9.74028 9 9V2H11V9C11 10.8638 9.72523 12.4299 8 12.874L8 22H6L6 12.874C4.27477 12.4299 3 10.8638 3 9ZM14.7337 14.1062H18V4.21534C17.1587 4.60068 16.4355 5.51488 15.8593 7.0518C15.1639 8.90669 14.8039 11.4035 14.7337 14.1062ZM18 16.1062V22H20V15.1062V3V2H19C16.4419 2 14.8745 3.98107 13.9866 6.34975C13.0857 8.7529 12.7208 11.9157 12.7208 15.1062V16.1062H13.7208H18Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: { type: String, required: false, default: 'currentColor' },
    size: { type: Number, required: false, default: 24 },
  },
};
</script>
